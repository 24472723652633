import { useEffect } from "react";

import { internalBase } from "../../config";

const InternalPage = ({ params: { path } }) => {
    useEffect(
        () => (window.location.href = new URL(path, internalBase).toString()),
        [path]
    );

    return null;
};
export default InternalPage;
